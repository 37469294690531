import logo from './logo.svg';
import './App.css';

import Button from '@mui/material/Button';


import { useEffect, useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';

import TextField from '@mui/material/TextField';

function App() {
  let [step, SetStep] = useState(0);
  let [type, SetType] = useState('');
  let [Bussines, SetBussines] = useState('');
  let [Device, SetDevice] = useState('');
  let [Email, SetEmail] = useState('');
  let [Amount, SetAmount] = useState('');
  let [Loader, SetLoader] = useState(false);
  let [StepBefore, SetStepBefore] = useState(0);

  let [typeMail, SettypeMail] = useState('');
  let [userTypeMail, SetuserTypeMail] = useState('');
  let [bussinessMail, SetbussinessMail] = useState('');
  let [amountMail, SetamountMail] = useState('');
  let [deviceMail, SetdeviceMail] = useState('');
  let [waterMail, SetwaterMail] = useState('');

  let [Water, SetWater] = useState('');

  const StepSubTwo = (typeUser) => {
    SetuserTypeMail(typeUser)
    if(typeUser == 'Bedrijf'){

      SetStep(3)
    }
   else if(typeUser == 'Particulier'){

    if(type == 'product_cat[31]'){
      SetStep(5)
  
      SetBussines('branche[35]')
    }else{
      SetStep(6)
    }
    
     
    }
 

  }


  const StepTwo = (type) => {
    SettypeMail(type)
    if(type == 'Keukenkraan'){
      SetType('product_cat[73]')
      SetStep(6)
    
    }
   else if(type == 'Waterkoeler'){
      SetType('product_cat[31]')
      SetStep(2)
     
    }
 

  }

  const startAgain = ()=>{
   SetStep(0)
  }


  const StepThree = (bussiness) => {
    SetbussinessMail(bussiness)

    if(bussiness == 'Bedrijven'){
      SetBussines('branche[29]')
      if(type == 'product_cat[31]'){
        SetStep(4)
      }else{
        SetStep(5)
      }
    
    }
    else if(bussiness == 'Scholen'){
      SetBussines('branche[53]')
      if(type == 'product_cat[31]'){
        SetStep(4)
      }else{
        SetStep(5)
      }
    
    }
    else if(bussiness == 'Horeca'){
      SetBussines('branche[56]')
      if(type == 'product_cat[31]'){
        SetStep(4)
      }else{
        SetStep(5)
      }
    
    }
    else if(bussiness == 'Showrooms'){
      SetBussines('branche[30]')
      if(type == 'product_cat[31]'){
        SetStep(4)
      }else{
        SetStep(5)
      }
    
    }
    else if(bussiness == 'Zorginstellingen'){
      SetBussines('branche[45]')
      if(type == 'product_cat[31]'){
        SetStep(4)
      }else{
        SetStep(5)
      }
    
    }

  }


  const StepSubThree = (type) => {
    SetamountMail(type)
    if(type == '1 - 5'){   
      SetAmount(type)
    }
   else if(type == '6 - 15'){   
    SetAmount(type)
  }
  if(type == '16 - 25'){   
    SetAmount(type)
  }
  if(type == '26 - 50'){   
    SetAmount(type)
  }if(type == '50+'){   
    SetAmount(type)
  }
  SetStep(5)
 

  }
  const StepFour = (device) => {
    SetdeviceMail(device)
    if(device == 'Tafelmodel'){
      SetType('product_cat[32]')
    }else if( device == 'Vrijstaand'){
      SetType('product_cat[49]')
    }else if(device == 'Wandmodel'){
      SetType('product_cat[67]')
    }
    SetDevice(device)
    SetStep(6)
  }

  const StepFive = (water) => {
    SetwaterMail(water)
    if(water == 'Ongekoeld & Gekoeld'){
      SetWater('water-opties[138]')
    }else if( water == 'water-opties[139]'){
      SetWater('product_cat[49]')
    }else if(water == 'Ongekoeld, Gekoeld & Heetwater'){
      SetWater('water-opties[140]')
    }
    else if(water == 'Gekoeld, Bruis & Heetwater'){
      SetWater('water-opties[141]')
    }

    SetStep(7)
  }

  useEffect(()=>{
     if(step === 7){
        setTimeout(()=>{
          SearchResults()
        },3000)
      } 
  })

  const SearchResults = () =>{
    SetLoader(true)
 
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('Origin','http://localhost:3000');
    headers.append('Access-Control-Allow-Methods', "POST, GET, OPTIONS, DELETE, PUT")
   fetch("https://us-central1-cashierr-pixelpros.cloudfunctions.net/sprankle", {
  
        method: 'POST',
    headers: headers,
    body: JSON.stringify({email:Email,typeMail:typeMail,userTypeMail:userTypeMail,bussinessMail:bussinessMail,amountMail:amountMail,deviceMail:deviceMail,waterMail:waterMail})
      }).then(function(response) {
        window.parent.location.href = 'https://sprankle.info/keuzehulp/?filters='+type+'|'+Bussines+'|'+Water;
      })

    
    
   
   



  }
  return (
    <div className={'container-fluid keuzehulp-container-fluid'}>
     

        <div className='row'>
      
          {step == 0 ?
            <div className='keuzehulp-col choose-buttons'>
            <div id="close-choosehelper">  <Button variant="text" className='close' id="close" onClick={()=>window.top.postMessage('hello', '*')}>Sluiten</Button></div>
                <h2>Welkom bij de <br/> <span className='sprankle-blue'>keuzehulp!</span></h2>
                <p>We helpen je graag verder in je<br/>zoektocht naar het <b>perfecte toestel</b></p>
                <Button variant="contained" onClick={()=>SetStep(1)} >Start keuzehulp</Button>
            </div>
            :null}
      
              {step == 1 ?
                    <div className=' keuzehulp-col choose-buttons'>
                       <Button variant="text" className='back' onClick={()=> startAgain()}>Begin opnieuw</Button>
                             <div id="close-choosehelper">  <Button variant="text" className='close' id="close" onClick={()=>window.top.postMessage('hello', '*')}>Sluiten</Button></div>
                <h2>Waar ben je naar <br/><span className='sprankle-blue '> op zoek?</span></h2>
                
                <Button variant="contained" onClick={()=> StepTwo('Keukenkraan')}>Keukenkraan</Button>
                <Button variant="contained" onClick={()=> StepTwo('Waterkoeler')}>Waterkoeler</Button>
               
            </div>
            :null}
                   {step == 2 ?
                    <div className=' keuzehulp-col choose-buttons'>
                        <Button variant="text" className='back' onClick={()=> startAgain()}>Begin opnieuw</Button>
                             <div id="close-choosehelper">  <Button variant="text" className='close' id="close" onClick={()=>window.top.postMessage('hello', '*')}>Sluiten</Button></div>
                <h2>Ik zoek een product als <br/><span className='sprankle-blue '> bedrijf of particulier.</span></h2>
                
                <Button variant="contained" onClick={()=> StepSubTwo('Bedrijf')}>Bedrijf</Button>
                <Button variant="contained" onClick={()=> StepSubTwo('Particulier')}>Particulier</Button>
               
            </div>
            :null}
              {step == 3 ?
                     <div className=' keuzehulp-col choose-buttons choose-buttons'>
                        <Button variant="text" className='back' onClick={()=> startAgain()}>Begin opnieuw</Button>
                              <div id="close-choosehelper">  <Button variant="text" className='close' id="close" onClick={()=>window.top.postMessage('hello', '*')}>Sluiten</Button></div>
                <h2>Kies je type <br/> <span className='sprankle-blue'>onderneming</span></h2>
                
                <Button variant="contained"  onClick={()=> StepThree('Bedrijven')}>Bedrijven</Button>
                <Button variant="contained"  onClick={()=> StepThree('Scholen')}>Scholen</Button>
                <Button variant="contained"  onClick={()=> StepThree('Horeca')}>Horeca</Button>
                <Button variant="contained"  onClick={()=> StepThree('Showrooms')}>Showrooms</Button>
                <Button variant="contained"  onClick={()=> StepThree('Zorginstellingen')}>Zorginstellingen</Button>
                
            </div>
            :null}
                {step == 4 ?
                     <div className=' keuzehulp-col choose-buttons choose-buttons'>
                        <Button variant="text" className='back' onClick={()=> startAgain()}>Begin opnieuw</Button>
                              <div id="close-choosehelper">  <Button variant="text" className='close' id="close" onClick={()=>window.top.postMessage('hello', '*')}>Sluiten</Button></div>
                <h2>Hoeveel <br/> <span className='sprankle-blue'>gebruikers</span> zal het toestel hebben?</h2>
                
                <Button variant="contained"  onClick={()=> StepSubThree('1 - 5')}>1 - 5</Button>
                <Button variant="contained"  onClick={()=> StepSubThree('6 - 15')}>6 - 15</Button>
                <Button variant="contained"  onClick={()=> StepSubThree('16 - 25')}>16 - 25</Button>
                <Button variant="contained"  onClick={()=> StepSubThree('S26 - 50')}>26 - 50</Button>
                <Button variant="contained"  onClick={()=> StepSubThree('50+')}>50+</Button>
                
            </div>
            :null}
            {step == 5 ?
                   <div className=' keuzehulp-col choose-buttons'>
                      <Button variant="text" className='back' onClick={()=> startAgain()}>Begin opnieuw</Button>
                            <div id="close-choosehelper">  <Button variant="text" className='close' id="close" onClick={()=>window.top.postMessage('hello', '*')}>Sluiten</Button></div>
                <h2>Welk <span className='sprankle-blue'>soort<br/>toestel</span> zoek je?</h2>
                
                <Button variant="contained"  onClick={()=> StepFour('Tafelmodel')}>Tafelmodel</Button>
                <Button variant="contained"  onClick={()=> StepFour('Vrijstaand')}>Vrijstaand</Button>
                <Button variant="contained"  onClick={()=> StepFour('Wandmodel')}>Wandmodel</Button>    
            </div>
            :null}
             {step == 6 ?
             
             <div className='keuzehulp-col choose-buttons'>
                <Button variant="text" className='back' onClick={()=> startAgain()}>Begin opnieuw</Button>
                      <div id="close-choosehelper">  <Button variant="text" className='close' id="close" onClick={()=>window.top.postMessage('hello', '*')}>Sluiten</Button></div>
                <h2>Welke <span className='sprankle-blue'>soorten<br/>water</span> wil je?</h2>
                
                <Button variant="contained"  onClick={()=> StepFive('Ongekoeld & Gekoeld')}>Ongekoeld & Gekoeld</Button>
                <Button variant="contained"  onClick={()=> StepFive('Ongekoeld, Gekoeld & Bruiswater')}>Ongekoeld, Gekoeld & Bruiswater</Button>
                <Button variant="contained"  onClick={()=> StepFive('Ongekoeld, Gekoeld & Heetwater')}>Ongekoeld, Gekoeld & Heetwater</Button>
                <Button variant="contained"  onClick={()=> StepFive('Gekoeld, Bruis & Heetwater')}>Gekoeld, Bruis & Heetwater</Button>    
            </div>
            :null}
              {step == 7 ?
             
             <div className=' keuzehulp-col choose-buttons'>
                <Button variant="text" className='back' onClick={()=> startAgain()}>Begin opnieuw</Button>
                      <div id="close-choosehelper">  <Button variant="text" className='close' id="close" onClick={()=>window.top.postMessage('hello', '*')}>Sluiten</Button></div>
                 <h2>Laatste stap! We zoeken de juiste <span className='sprankle-blue'>producten</span><br/>voor je bij elkaar.</h2>
                 <CircularProgress  />
                {/*} <p>EMAILADRES*</p>
                 <TextField id="outlined-basic" label="Je email" variant="outlined" value={Email}  onChange={(e)=> SetEmail(e.target.value)}/>*/}
                 {/*Loader ? <CircularProgress  />: <Button variant="contained" onClick={()=> SearchResults()}>Vestuur</Button>*/    }
             </div>
             :null}
          
        </div>
 

     
    </div>
  );
}

export default App;
